// extracted by mini-css-extract-plugin
export var container = "qXc15XtZ";
export var content = "uO5QnHZe";
export var header = "_6UgipBhK";
export var backButton = "ZZpThMmC";
export var body = "nnIgLXTc";
export var npm = "X-JURdpy";
export var form = "OisOGRA4";
export var sendEmail = "rssBVXE9";
export var emailInstructions = "e2zRZ04k";
export var link = "aZhzFt20";
export var link__signin = "A+oHE6my";